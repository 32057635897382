/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import GA4 from 'utils/GA4'

import { Helmet } from 'react-helmet'

import Hero from 'components/Hero'
import Layout from 'components/Layout'

import StacklaWidget from 'components/StacklaWidget'
import renderContent from 'utils/componentRenderer'
import { filterRepeatedContent, groupContentTypes } from 'utils/contentUtils'
import { Spacing50 } from 'styles/sharedStyle'

const SectionDetailPage = (props) => {
  const {
    data,
    pageContext: { pageLevel },
  } = props
  const {
    page,
    page: { hero, content, stacklaCarousel, jsonLdSchema, pageType, village },
  } = data

  const media = hero && hero.media && hero.media[0]
  const hasHeroImage =
    (media && media.portrait && media.landscape) ||
    (media &&
      media.videoPlaceholder &&
      media.videoPlaceholder.landscape &&
      media.videoPlaceholder.portrait)
  const heroImage = hasHeroImage
    ? {
        portrait: media.portrait || media.videoPlaceholder.portrait,
        landscape: media.landscape || media.videoPlaceholder.landscape,
        altText:
          media.altText ||
          (media.videoPlaceholder ? media.videoPlaceholder.altText : ''),
      }
    : {}
  const hasHeroVideo = media && media.videoPortrait && media.videoLandscape
  const heroVideo = hasHeroVideo
    ? {
        portrait: media.videoPortrait,
        landscape: media.videoLandscape,
        opacity: media.opacity,
      }
    : {}
  const heroProps = hero
    ? {
        eyebrow: hero.eyebrow,
        content: hero.headline,
        image: heroImage,
        video: heroVideo,
        logo: page.brandLogo,
        villageSlug:
          pageLevel === 'collection'
            ? 'The Bicester Collection'
            : village?.name,
      }
    : null

  const contentToRender =
    !!content &&
    groupContentTypes(
      filterRepeatedContent(content, ['ContentfulEntityEventEnt04']),
      ['ContentfulCompPromoCopyCtaPro01', 'ContentfulPageTemplateEditorialT08']
    )

  useEffect(() => {
    if (pageType.pageTypeSlug === 'getting-here' && page?.village?.name) {
      GA4('getting_here_url', {
        village_name: page?.village?.name,
        click_url: document?.referrer,
      })
    }
  }, [])

  return (
    <Layout {...props}>
      <Helmet>
        {jsonLdSchema && jsonLdSchema.jsonLdSchema ? (
          <script type="application/ld+json">
            {jsonLdSchema.jsonLdSchema}
          </script>
        ) : null}
      </Helmet>

      {heroProps && <Hero {...heroProps} />}

      <Spacing50 />

      {renderContent(contentToRender, props, true)}

      <Spacing50 />

      {!!stacklaCarousel && !!stacklaCarousel.code && (
        <StacklaWidget
          code={stacklaCarousel.code.code}
          eyebrow={stacklaCarousel.eyebrow}
          headline={stacklaCarousel.headline}
          ctaLabel={stacklaCarousel.ctaLabel}
          ctaUrl={stacklaCarousel.ctaUrl}
        />
      )}
    </Layout>
  )
}

SectionDetailPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    pageLevel: PropTypes.string.isRequired,
  }).isRequired,
}

export const sectionDetailPageQuery = graphql`
  query(
    $id: String!
    $openingHoursId: String
    #  $dateNow: Date!
    $dateStart: Date!
    $dateEnd: Date!
    $nodeLocale: String!
    $collectionId: String
    $vrId: String
    $pageTypeSlug: String
    $villageSlug: String
    $villageId: String!
    $needBrands: Boolean = false
    $needContactIcons: Boolean = false
  ) {
    page: contentfulPageTemplateSectionDetailT03(id: { eq: $id }) {
      locale: node_locale
      jsonLdSchema {
        jsonLdSchema
      }
      pageType {
        pageTypeSlug: slug
      }
      village {
        name
        villageSlug: slug
        currency
        home: page_template_home_t01 {
          ...headerFooterVillage
          locale: node_locale
        }
        openingHours {
          ...villageOpeningHours
        }
        labels: label_brand_details_lab01 {
          openingLabel
          closingLabel
          reopeningLabel
          ...virtualShoppingIconsAndLabelsQuery
        }
        defaultLocale
        openingStatusLabel
      }
      hero {
        title
        headline
        eyebrow
        ...heroMediaQuery
      }
      content {
        __typename
        ... on Node {
          ...multipleComponents
        }
      }
      pageTitle
      pageDescription
      stacklaCarousel {
        code {
          code
        }
        eyebrow
        headline
        ctaLabel
        ctaUrl
      }
      search {
        categorySearchLabel
        ctaLabel
        headline
        noResults
        promptText
        resultLabelPlural
        resultLabelSing
      }
      hideFromSearchEngine
    }
    openingHoursExceptions: allContentfulCompOpeningHoursExceptionOph04(
      filter: {
        date: { lte: $dateEnd, gte: $dateStart }
        comp_opening_hours_village_oph01: {
          elemMatch: { id: { eq: $openingHoursId } }
        }
      }
    ) {
      exceptions: edges {
        node {
          date(formatString: "DD-MM-YYYY")
          openingTime
          closingTime
          closedFlag
          label
        }
      }
    }
    openingHoursLabels: allContentfulLabelOpeningHoursLab04(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          ...contentfulOpeningHoursLabels
        }
      }
    }
    collectionPage: contentfulPageTemplateHomeT01(id: { eq: $collectionId }) {
      header {
        ...header
      }
      footer {
        ...footer
      }
    }
    valueRetailPage: contentfulPageTemplateHomeT01(id: { eq: $vrId }) {
      header {
        ...header
      }
      footer {
        ...footer
      }
    }
    eventLabels: allContentfulLabelVillageLab09(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          eventDateTimeHeading
          eventLocationHeading
          eventMoreInfoLabel
          eventPhoneLabel
          eventEmailLabel
          eventViewOnMapLabel
        }
      }
    }

    articleLabels: allContentfulLabelStoryArticleLab06(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          filterEyebrow
          filterHeader
          filterDropdownPrompt
          loadMoreStoriesCta
        }
      }
    }

    brands: allContentfulPageTemplateBrandDetailT06(
      filter: {
        node_locale: { eq: $nodeLocale }
        village: { contentful_id: { eq: $villageId } }
      }
    ) @include(if: $needBrands) {
      edges {
        node {
          name
          slug
          openStatus: flags
          hideBrand
          categories {
            name
            label
          }
          services {
            title
          }
          nodeLocale: node_locale
          pageType {
            pageTypeSlug: slug
          }
          content {
            ... on ContentfulCompVirtualShoppingDetailsVsd01 {
              __typename
              whatsapp
              productCatalogue
              bookingUrl
              virtualShoppingEmail
              virtualShoppingPhone
              weChatID
              weChatImage {
                ...ResponsiveImageQuery
              }
            }
          }
        }
      }
    }

    #    featuredOffers: allContentfulEntityOfferEnt05(
    #      filter: {
    #        node_locale: { eq: $nodeLocale }
    #        offerRunFinishDate: { gte: $dateNow }
    #      }
    #      sort: { fields: offerRunFinishDate }
    #    ) {
    #      nodes {
    #        id: contentful_id
    #        location {
    #          locationId: contentful_id
    #        }
    #        eyebrow
    #        headline
    #        description {
    #          description
    #        }
    #        offerDetailUrl
    #        offerExpirationLabel
    #        offerRunStartDate(formatString: "D MMMM")
    #        offerRunFinishDate(formatString: "D MMMM YYYY")
    #        offerImage {
    #          ...heroMediaQuery
    #        }
    #      }
    #    }

    mapTranslations: allContentfulEntityPageTypeEnt06(
      filter: { instanceName: { eq: "map" }, node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          nodeLocale: node_locale
          slug
        }
      }
    }

    labels: allContentfulLabelVillageLab09(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          visitBoutiqueLabel
        }
      }
    }

    memDaysOnboarding: contentfulCompMemDayOnb01(
      node_locale: { eq: $nodeLocale }
      village: { slug: { eq: $villageSlug } }
      pageTypes: { elemMatch: { slug: { eq: $pageTypeSlug } } }
    ) {
      ...memDaysOnboarding
    }

    allMemorableDays: allContentfulPageTemplateMemorableDaysT14(
      filter: {
        node_locale: { eq: $nodeLocale }
        village: { slug: { eq: $villageSlug } }
      }
    ) {
      edges {
        node {
          ...memDaysSlugs
        }
      }
    }
    allMembershipPopUps: allContentfulCompMembershipPopUp(
      filter: {
        node_locale: { eq: $nodeLocale }
        village: { slug: { eq: $villageSlug } }
        active: { eq: true }
      }
    ) {
      totalCount
      edges {
        node {
          ...membershipPopUp
        }
      }
    }
  }
`

export default SectionDetailPage
